body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  
  bottom: 10px; /* Adjust the value to control the vertical position from the bottom */
  right: 10px; /* Adjust the value to control the horizontal position from the right */
  /*background-color: #f0f0f0;*/
  /*padding: 8px;*/
  /*border-radius: 4px;*/
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
}
.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.required-file {
  content: "*";
  color: red;
}

.container {
  display: flex;
  margin: 10px;
  border: 1px solid lightsteelblue;
  padding: 10px;
}

.textbox {
  flex: 1;
  height: 20px;
}

.text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  font-weight: bolder;
  margin-left: 10px;
  margin-right: 10px; /* Adjust the margin as needed */
}

.button-container{
  display: flex;
  justify-content: flex-end;
}

.butt{
  display: flex;
  margin: 10px;
  border: 0.5px solid lightpink;
  padding: 10px;
}

.h{
  flex: 1;
  display: block;
  text-align: center;
}

.head{
  display: flex;
  margin: 10px;
  border: 3px solid teal;
  padding: 10px;
}

.csv-button{
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
}

.login-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}